.modal-stop-routes {
  h2 {
    font-style: normal;
    font-weight: $font-weight;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 21px;
    color: #333;
  }

  .section-margin-large {
    margin: 0 80px;

    label {
      svg {
        margin-right: 10px !important;
      }
    }
  }
/* stylelint-disable selector-class-pattern */
  .ReactModal__Content {
    border: none !important;
    border-radius: 5px !important;
    width: 720px !important;
    max-height: 93% !important;
    height: fit-content;
    top: 50% !important;
    left: 50% !important;
    overflow: hidden !important;
    margin-right: -50%;
    margin-bottom: -50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    animation: none;
    padding: 0 !important;
  }

  #close {
    position: absolute;
    top: 30px;
    right: 30px;

    .close-button {
      background: transparent;
      border: none;
      cursor: pointer;
    }
  }

  .section-margin-small {
    margin: 0 60px;
  }

  .title-container {
    margin: 64px 0 22px;

    .title {
      font-size: 22px;
      padding-bottom: 20px;
    }

    .stop {
      font-size: 20px;
      padding-right: 8px;
    }
  }

  .modal {
    padding-top: 0;
    width: 720px;
    font-family: $font-family;
    margin: 0;
    overflow-y: auto;

    .route-rows {
      display: flex;
      flex-direction: column;
      overflow: hidden auto;
      max-height: 325px;

      .row {
        min-height: 30px;
        display: flex;
        align-items: center;
        margin: 10px 0;

        .check-box {
          svg {
            flex: none;
          }
        }

        .option-checkbox-container {
          width: 32px;
        }

        .all {
          margin-bottom: 5px;
          margin-left: 8px;
        }

        .empty-space,
        .routeInfo {
          min-width: 35%;
        }

        .vehicle {
          padding-right: 10px;
          padding-left: 10px;
          display: flex;
        }

        .route-number {
          font-style: normal;
          font-weight: $font-weight-bigger;
          font-size: 18px;
          line-height: 22px;
          color: #333;
          display: inline-block;
          text-wrap: nowrap;
        }

        .destination {
          display: flex;
          justify-content: flex-start;
        }

        .renamedDestinations {
          display: flex;
          width: 100%;

          .en,
          .fi,
          .et {
            width: 100%;
            margin-right: 13px;

            &.readonly {
              font-size: 16px;
              width: 100%;
              border: 0;

              &::placeholder {
                color: gray;
              }
            }

            &::placeholder {
              color: gray;
            }
          }
        }

        &.small {
          position: sticky;
          min-height: 0;
          margin: 0;
          margin-bottom: 10px;

          .lang {
            font-style: normal;
            font-weight: $font-weight;
            font-size: 13px;
            line-height: 15px;
            letter-spacing: -0.02em;
            display: flex;

            &.fi,
            &.et,
            &.en {
              width: 100%;
            }
          }
        }
      }
    }

    .button-container {
      background: white;
      width: 100%;
      margin: 30px 0;
      display: flex;
      justify-content: center;

      .btn {
        background: $primary-color;
        height: 40px;
        width: 240px;
        border: none;
      }
    }

    .divider {
      border: 1px solid #ddd;
      margin-top: 25px;
    }

    .divider-routes {
      border: 1px solid #ddd;
    }

    .timeshift {
      p {
        font-style: normal;
        font-weight: $font-weight;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: -0.02em;
      }

      .show-departures-over {
        font-style: normal;
        font-weight: $font-weight-bigger;
        font-size: 15px;
        line-height: 18px;
        color: #333;
        display: flex;
        align-items: center;
      }

      .dd-select {
        width: 100px;
      }
    }

    .title-and-no-renaming {
      display: flex;

      .no-renaming {
        width: 50%;
        text-align: end;

        h2 {
          color: $primary-color;
          cursor: pointer;
          position: relative;
          top: 5px;
          font-size: 15px;
          line-height: 18px;
        }
      }

      .title {
        width: 50%;
      }
    }
  }

  .show-settings {
    display: flex;
    flex-direction: column;

    .setting {
      display: flex;
      height: 30px;
      margin: 5px 0;

      label {
        bottom: 5px;
      }

      .option-checkbox-container {
        padding-top: 10px;
      }

      .setting-text {
        margin-left: 20px;
      }
    }
  }
}
