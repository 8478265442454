$preview-container-height-portrait: 590px;
$preview-container-width-portrait: 405px;
$preview-grid-rows-height-portrait-tighten: 266px;
$preview-container-width: 640px;
$preview-container-height: 365px;

.title-bar {
  font-family: $font-family;
  font-weight: $monitor-font-weight;
  display: grid;
  grid-template-columns: 14vw auto 7.3vw 14vw;
  height: 15vh;
  margin: 0 1%;

  &.multiDisplay { /* stylelint-disable-line */
    grid-template-columns: 14vw 1fr 14vw;
  }

  .weather-container {
    display: grid;
    grid-template-rows: 57% 43%;
    align-self: center;

    .temperature-container {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: min(3.7vh, 3vw);
    }

    .icon-container {
      display: flex;
      justify-content: center;

      svg {
        height: min(5vw, 7vh) !important;
        width: min(5vw, 7vh) !important;
      }
    }
  }

  &.portrait {
    grid-template-columns: 24vw auto 24vw;
    height: 8.3vh !important;

    //.title-text {
    //  font-size: min(5vw, 4vh);
    //}

    .title-time {
      font-size: min(6vw, 5vh);
    }

    .title-logo {
      #logo {
        width: min(100%, 18vh);
      }

      .vaasa {
        width: min(100%, 12vh);
      }

      .linkki {
        width: min(100%, 7vh);
      }

      .oulu {
        width: min(100%, 12vh);
      }
    }
  }

  &.preview {
    display: grid;
    height: 55px;
    grid-template-columns: calc(0.14 * #{$preview-container-width}) auto calc(0.073 * #{$preview-container-width}) calc(0.14 * #{$preview-container-width});
    margin: 0 5px;

    &.multiDisplay { /* stylelint-disable-line */
      grid-template-columns: calc(0.14 * #{$preview-container-width}) auto calc(0.14 * #{$preview-container-width});
    }

    .weather-container-no-icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .weather-container {
      display: grid;
      grid-template-rows: 58% 42%;

      .temperature-container {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
      }

      .icon-container {
        display: flex;
        justify-content: center;

        svg {
          height: 35px !important;
          width: 35px !important;
        }
      }
    }

    &.portrait {
      display: grid;
      grid-template-columns: calc(0.14 * #{$preview-container-width-portrait}) auto calc(0.14 * #{$preview-container-width-portrait});
      height: calc(0.0948 * #{$preview-container-height-portrait}) !important;
      padding: 0 5px;
      &.no-padding {
        padding: 0;
      }
    }

    .linkki {
      width: 50px !important;
    }

    .oulu {
      width: 100px !important;
    }
  }

  .title-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &.portrait {
      &.hsl {
        svg {
          width: calc(2 * min(7.5vh, 10vw)) !important;
          height: min(7.5vh, 10vw) !important;
        }
      }
    }

    #logo {
      width: min(100%, 20vh);
    }

    &.hsl {
      svg {
        width: calc(2 * min(9.48vh, 7vw)) !important;
        height: min(9.48vh, 7vw) !important;
      }
    }

    .linkki {
      width: min(100%, 10vh);
    }

    .oulu {
      width: min(100%, 20vh);
    }

    .vaasa {
      width: min(100%, 20vh);
      padding-left: 0.4vw;
    }

    &.preview {
      min-width: calc(0.14 * #{$preview-container-width}) !important;
      padding: calc(0.015 * #{$preview-container-height}) 0 !important;

      &.hsl {
        svg {
          width: calc(2 * min(calc(0.09 * #{$preview-container-height}), calc(0.09 * #{$preview-container-width}))) !important;
          height: min(calc(0.09 * #{$preview-container-height}), calc(0.09 * #{$preview-container-width})) !important;
        }
      }

      #logo {
        width: calc(0.14 * #{$preview-container-width}) !important;
      }
    }
  }
}

.title-text {
  font-weight: $monitor-font-weight-bigger;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  white-space: nowrap;
  overflow: hidden;
  //mask-image:
  //  linear-gradient(
  //    to left,
  //    transparent,
  //    rgb(0 0 0 / 20%) 5px,
  //    rgb(0 0 0 / 100%) 25px,
  //    rgb(0 0 0 / 100%)
  //  );
  justify-content: center;
  align-content: center;
  align-items: center;
  //font-size: calc(90vw/(#{var(--textWidth)}));//min(8vh, 5vw);

  &.preview {
    padding: calc(0.015 * #{$preview-container-height}) 0 !important;
    //font-size: 2rem !important;

    //&.portrait {
    //  font-size: 1.5rem !important;
    //}
  }
}

.multi-display-titles {
  font-weight: $monitor-font-weight;
  display: flex;
  color: #fff;
  width: 100%;
  overflow: hidden;

  div {
    margin: 0 0.1em;
    flex: 1;
  }
}

.left-title {
  &.preview {
    font-size: 20px;
  }

  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  //font-size: 4vw;
  //mask-image:
  //  linear-gradient(
  //    to left,
  //    transparent,
  //    rgb(0 0 0 / 20%) 5px,
  //    rgb(0 0 0 / 100%) 25px,
  //    rgb(0 0 0 / 100%)
  //  );
}

.right-title {
  &.preview {
    font-size: 20px;
  }

  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  //font-size: 4vw;
  //mask-image:
  //  linear-gradient(
  //    to left,
  //    transparent,
  //    rgb(0 0 0 / 20%) 5px,
  //    rgb(0 0 0 / 100%) 25px,
  //    rgb(0 0 0 / 100%)
  //  );
}

.title-text-stop {
  display: flex;
}

.title-time-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 0;
}

.title-time {
  font-weight: $monitor-font-weight;
  display: flex;
  font-size: min(4.5vw, 6.5vh);
  flex: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 0.2em;
  background-color: white;
  color: $monitor-background-color;
  padding: 0 1.25vw !important;

  &.preview {
    font-weight: $monitor-font-weight;
    padding: 0 calc(0.0125 * #{$preview-container-width}) 0 !important;
    max-width: 100%;
    max-height: 100%;
    font-size: 1.7rem !important;

    &.portrait {
      padding: 0 calc(0.0125 * #{$preview-container-width-portrait}) 0 !important;
      font-size: 1.4rem !important;
    }
  }

  time {
    span {
      animation: time-animation 2s steps(1) infinite;
    }
  }
}

@keyframes time-animation {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}
