
$preview-container-width: 640px;
$preview-container-height: 365px;
$preview-container-height-portrait: 590px;
$preview-container-height-portrait-tighten: 285px;
$preview-container-width-portrait: 405px;
$preview-grid-rows-height-portrait-tighten: 266px;
$preview-grid-rows-margin: 15px;
$preview-grid-divider: 2px;

/* stylelint-disable color-function-notation */
.monitor-overlay {
  transition: opacity 1s;
  z-index: 10000;
  width: 60%;
  height: 15%;
  background-color: rgba(0, 0, 0, 70%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;

  &.show {
    opacity: 1;
  }

  &.hide {
    opacity: 0;
  }

  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 50px;

  .loading-container {
    min-width: 40vh;
    flex: 0;
    border-radius: 500px;
    height: 50%;
    width: 40%;

    svg {
      width: 40px !important;
      height: 40px !important;
    }
  }

  .link {
    display: flex;
    min-width: 40vh;
    background-color: $monitor-background-color;
    height: 50%;
    font-family: $font-family;
    text-decoration: none;
    color: white;
    border-radius: 500px;
    align-items: center;
    justify-content: center;
    font-size: 3vh;
  }
}

.demo-button {
  position: absolute;
  background-color: transparent;
  z-index: 9999;
  width: 25vh;
  height: 100%;
  bottom: 0;
}

.main-content-container.preview {
  padding: 0;

  .grid-row.alert {
    left: -5px;
  }

  .monitor-container {
    .grid.two-cols {
      margin: 0 5px;
      width: calc(50% - 8px);

      .row-with-separator.alert {
        width: calc(200% + 35px) !important;
        left: -9px;
      }
    }

    .no-departures-text-container {
      .no-departures-text {
        font-size: 40px;

        &.closed-stop {
          white-space: pre-line;
          font-size: 24px;
        }
      }
    }

    .divider {
      min-width: 2px;
      margin: 0 3px;
    }

    .separator {
      border-top: 1px dotted #fff;
    }

    .grid-rows {
      height: calc(100% - 25px);

      &.portrait {
        .grid-row.alert {
          &.static {
            font-size: 14px !important;
          }
        }
      }

      .grid-row {
        .grid-col {
          svg {
            width: 24px !important;
            height: 24px !important;
            display: flex;
          }
        }

        &.alert {
          font-size: 35px !important;

          &.vertical {
            font-size: 25px !important;
          }

          &.static {
            font-size: 20px !important;
          }

          &.two-rows {
            padding-bottom: 2px;
          }

          &.three-rows {
            padding-bottom: 3px;
          }

          &.four-rows {
            padding-bottom: 4px;
          }
        }
      }
    }

    .grid {
      height: 100%;
      margin: 0 5px;

      .grid-headers {
        height: 25px;

        .grid-row {
          height: 25px;
        }

        .grid-header {
          font-size: 16px;
        }
      }
    }

    &.portrait {
      height: calc(100% - 30px);

      .grid-rows.tightened {
        height: calc(50% - 12.5px);
      }
    }

    &.preview {
      &.portrait {
        height: 534px;

        &.tightened {
          height: 534px;
        }
      }
    }
  }
}

.main-content-container {
  background-color: $monitor-background-color;
  font-family: $monitor-font;
  font-weight: $monitor-font-weight;
  letter-spacing: -0.2px;
  color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;

  .monitor-container {
    flex: 1;

    .rows8 .no-departures-text-container {
      flex: 6;
    }

    .rows12 .no-departures-text-container {
      flex: 9;
    }

    .no-departures-text-container {
      flex: 3;
      border-top: calc(0.2 * 1vh) solid #fff;
      width: 100%;
      display: flex;
      text-align: center;
      align-self: center;
      align-items: center;

      .no-departures-text {
        width: 100%;
        display: flex;
        text-align: center;
        flex: 1;
        justify-content: center;
        align-items: center;
        font-size: calc((#{var(--height)} - 30vh) / 7);

        &.closed-stop {
          white-space: pre-line;
          font-size: calc((#{var(--height)} - 30vh) / 10);
        }
      }
    }

    &.portrait {
      height: calc(100% - 12.75vh);
    }

    .divider {
      border-left: calc(0.3 * 1vh) solid #fff;
      width: 0;
      margin-left: 0.3vw;
      margin-right: 0.3vw;
      height: 98%;
    }

    .separator {
      width: 100%;
      border-top: calc(0.3 * 1vh) dotted #fff;
      height: 0;

      &.first {
        border-top: calc(0.2 * 1vh) solid #fff;
      }
    }

    &.two-cols {
      display: flex;

      .grid {
        width: calc(50% - 32px - 0.3vw);
      }

      &.two-cols {
        .row-with-separator.alert {
          width: calc(200% + 60px + 0.7vw);
        }
      }
    }

    .grid {
      margin: 0 15px;
      height: calc(100% - 5vh); /* 100% - height of table headers */      
      &.portrait {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }
  }

  .grid-headers {
    &.rows16,
    &.rows24 {
      .grid-row > .grid-header {
        font-size: 2.5vh;
      }
    }

    .grid-row {
      display: grid;
      height: 5vh;

      .grid-header {
        align-self: center;
        font-size: 3.2vh;

        &.time {
          text-align: end;
        }
      }
    }
  }

  &.portrait {
    .grid-rows {
      position: relative;

      &.no-departures {
        flex: 1;

        .no-departures-text-container {
          flex: 1;
        }

        .row-with-separator.alert {
          height: calc(100% / 8) !important;
        }
      }
    }
  }

  .grid-rows {
    .alert-padding {
      flex: 1;
    }

    &.no-departures {
      .no-departures-text-container {
        flex: 3 !important;
      }

      .row-with-separator.alert {
        &.two-rows,
        &.three-rows {
          flex: 1 !important;
        }
      }
    }

    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    &.portrait {
      &.rows8 {
        .destination {
          display: block;
        }
      }

      &.tightened {
        height: 50%;

        .row-with-separator.alert {
          height: calc(100% / 4);
        }
      }

      .row-with-separator.alert {
        background-color: $monitor-background-color;
        position: absolute;
        bottom: 0;
        height: calc(100% / 8);
      }
    }

    &.rows8 {
      .destination {
        display: flex;
        align-items: baseline;
      }
    }

    &.rows12 {
      .destination {
        display: flex;
        align-items: baseline;
      }
    }

    &.rows16,
    &.rows24,
    &.rows6 {
      .destination {
        display: flex;
      }
    }

    .row-with-separator {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      &.two-rows {
        flex: 2 1 0;
      }

      &.three-rows {
        flex: 3 1 0;
      }

      &.four-rows {
        flex: 4 1 0;
      }

      &.alert {
        position: relative;
        left: -13px;
        width: calc(100% + 30px);

        .grid-row {
          width: 100%;
        }

        .separator {
          border-top: calc(0.3 * 1vh) solid white;
        }

        &.vertical {
          .grid-cols.alert-row {
            width: unset;
          }
        }
      }
    }

    .grid-row {
      height: 100%;
      display: grid;

      .grid-col {
        align-self: center;

        &.icon {
          svg {
            display: flex;
            width: var(--icon-size) !important;
            height: var(--icon-size) !important;
          }
        }

        .line-letter {
          font-size: smaller;
        }

        &.time {
          text-align: end;
          font-family: $monitor-font;
        }
      }

      &.is-cancelled {
        .time,
        .code {
          text-decoration-line: line-through;
          text-decoration-color: $alert-color;
          text-decoration-thickness: 0.4vh;
        }
      }

      &.two-rows {
        .destination > .via-destination {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          white-space: nowrap;
        }
      }

      .destination {
        svg {
          min-height: 0.9em !important;
          min-width: 0.9em !important;
          max-height: 0.9em !important;
          max-width: 0.9em !important;
          align-self: center;
        }

        .destination-row {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .metro-icon {
            position: relative;
            display: flex;
            margin: 0 3px;
          }
        }

        white-space: nowrap;
        overflow: hidden;
        mask-image:
          linear-gradient(
            to left,
            transparent,
            rgba(0, 0, 0, 20%) 5px,
            rgba(0, 0, 0, 100%) 25px,
            rgba(0, 0, 0, 100%)
          );

        .via-destination {
          font-size: smaller;
          white-space: pre;
          display: flex;

          .metro-icon-small {
            position: relative;
            margin: 0 3px;
            display: flex;
          }
        }
      }
    }
  }
}

.portrait {
  .grid-row.alert {
    font-size: calc((#{var(--height)} - 30vh) / 14) !important;
  }
}

.grid.portrait {
  .grid-row.alert.static {
    font-size: calc((#{var(--height)} - 30vh) / 24) !important;
  }

  .grid-row.alert.vertical {
    font-size: calc((#{var(--height)} - 30vh) / 20) !important;
  }
}

.grid-row {
  &.alert {
    flex: 1;
    color: white;
    background: $monitor-background-color;
    display: block !important;
    width: calc(100% + calc(2 * #{$preview-grid-rows-margin}));
    z-index: 1000;
    overflow: hidden;
    font-size: calc((#{var(--height)} - 30vh) / 9) !important;

    &.static {
      font-size: calc((#{var(--height)} - 30vh) / 12) !important;

      .grid-cols.alert-row {
        width: unset;
        display: flex;
        align-items: center;
      }
    }

    &.vertical {
      font-size: calc((#{var(--height)} - 30vh) / 12) !important;

      .alert-text {
        align-items: flex-start;
        height: 1px;

        .alert-separator {
          margin-top: 10px;
          width: 100%;
          display: flex;
          min-height: 2px;
          background-color: white;
        }

        white-space: unset;
        flex-direction: column;

        .single-alert {
          padding-top: 10px;
          padding-left: 0;
        }

        .single-alert:first-child {
          padding-top: calc(100% / 6);
        }
      }
/* stylelint-disable property-no-vendor-prefix */
      .animated {
        -moz-animation: alert-animation-vertical #{var(--speed)} linear infinite; /* stylelint-disable-line */
        -webkit-animation: alert-animation-vertical #{var(--speed)} linear infinite;
        animation: alert-animation-vertical #{var(--speed)} linear infinite;
      }
    }

    .alert-text {
      white-space: nowrap;
    }

    .single-alert {
      padding-left: 66%;
    }

    .single-alert:first-child {
      padding-left: 110%;
    }

    &.horizontal {
      .animated {
        -moz-animation: alert-animation #{var(--speed)} linear infinite;
        -webkit-animation: alert-animation #{var(--speed)} linear infinite;
        animation: alert-animation #{var(--speed)} linear infinite;
      }
    }
  }
  &.route-name {
    flex: 1;
    color: white;
    background: $monitor-background-color;
    display: block !important;
    width: calc(100% + calc(2 * #{$preview-grid-rows-margin}));
    z-index: 1000;
    overflow: hidden;
    white-space: nowrap;
    //font-size: calc((#{var(--height)} - 30vh) / 9) !important;

    .animated {
      -moz-animation: route-name-scroll-animation #{var(--speed)} linear infinite;
      -webkit-animation: route-name-scroll-animation #{var(--speed)} linear infinite;
      animation: route-name-scroll-animation #{var(--speed)} linear infinite;
    }
  }
}

@keyframes route-name-scroll-animation {
  0%, 15% {
    -moz-transform: translate(0);
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  85%, 100% {
    -moz-transform: translate(#{var(--animationWidth)});
    -webkit-transform: translate(#{var(--animationWidth)});
    transform: translate(#{var(--animationWidth)});
  }
}

@keyframes alert-animation-vertical {
  from {
    -moz-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    -moz-transform: translateY(#{var(--animationWidth)});
    -webkit-transform: translateY(#{var(--animationWidth)});
    transform: translateY(#{var(--animationWidth)});
  }
}

@keyframes alert-animation {
  from {
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -moz-transform: translateX(#{var(--animationWidth)});
    -webkit-transform: translateX(#{var(--animationWidth)});
    transform: translateX(#{var(--animationWidth)});
  }
}

.grid-cols {
  display: flex;
  justify-content: center;

  &.alert-row {
    display: block;
    padding: 0 $preview-grid-rows-margin;
    height: 100%;
    width: 100%;

    .alert-text {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
}

.grid-row.day {
  display: flex !important;

  .day-row {
    width: 100%;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: $monitor-background-color;
    background: white;
  }
}

.tilde {
  font-size: smaller;
}
